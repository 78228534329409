import React from 'react';
import "./faqs.css";
import {motion} from "framer-motion";

const FAQs = () => {

    const faqs = {
        show: {
          transition: {
            delayChildren: 0.4,
            staggerChildren: 0.1,
          },
        },
      };
    
      const faqsElementSlideUp = {
        hidden: {
          opacity: 0,
          y: 300,
        },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            ease: "easeInOut",
            duration: 0.6,
          },
        },
        exit: {
          opacity: 0,
          y: -300,
          transition: {
            ease: "easeInOut",
            duration: 0.8,
          },
        },
      };
    
      const faqsElementSlideLeft = {
        hidden: {
          opacity: 0,
          x: 300,
        },
        show: {
          opacity: 1,
          x: 0,
          transition: {
            ease: "easeInOut",
            duration: 0.6,
          },
        },
        exit: {
          opacity: 0,
          x: -100,
          transition: {
            ease: "easeInOut",
            duration: 0.8,
          },
        },
      };

  return (
    <div id="FAQs">
        <motion.div variants={faqs}
          initial="hidden"
          whileInView="show" className="inside-faq-container">
            <motion.div variants={faqsElementSlideUp} className="faqs-title">FREQUENTLY ASKED QUESTIONS (FAQs)</motion.div>
            <motion.div variants={faqsElementSlideUp} className="faqs-data">
                <div className="faqs-data-container">
                    <div className="faqs-question">Q1. How are Naturally Pahadi products different from others in the market?</div>
                    <div className="faqs-answers">Our products are produced with the least processing and adopting traditional methods for enhancing flavours, taste and nutritional values. We believe in conscious eating as a road to holistic health development. Our sourcing happens from the Himalayas.</div>
                </div>
                <div className="faqs-data-container">
                    <div className="faqs-question">Q2. How can kitchens and cafes get started with Naturally Pahadi products?</div>
                    <div className="faqs-answers">It is easy to get connected with us. Download our catalogue from the “Downloads” section.  Then reach out to our team through the contact information for personalized consultations. We are excited to collaborate with kitchens and cafes.</div>
                </div>
                <div className="faqs-data-container">
                    <div className="faqs-question">Q3. Can Naturally Pahadi products accommodate bulk orders for kitchens and cafes?</div>
                    <div className="faqs-answers">Our products are available in large quantities to cater for the requirements of kitchens and cafes. You have to reach out to our sales team to place orders.</div>
                </div>
                <div className="faqs-data-container">
                    <div className="faqs-question">Q4. Can Naturally Pahadi assist with menu planning by integrating their products?</div>
                    <div className="faqs-answers">We can help co-create recipes with our ingredients. This would help you to easily integrate our products in your kitchen and cafe.</div>
                </div>
                <div className="faqs-data-container">
                    <div className="faqs-question">Q5. How can kitchens and cafes connect with Naturally Pahadi for collaboration?</div>
                    <div className="faqs-answers">Our relationship with our clients is based on the passion to provide healthy foods. You can reach out to our team to realise how we can collaborate for the benefit of all.</div>
                </div>
            </motion.div>
        </motion.div>
    </div>
  )
}

export default FAQs;