import React from "react";
import "./products.css";
import { motion, useInView, useIsPresent } from "framer-motion";

const Products = () => {

  const products = {
    show: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };

  const productsElementSlideRight = {
    hidden: {
      opacity: 0,
      x: -300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: 100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  const productsElementSlideLeft = {
    hidden: {
      opacity: 0,
      x: 300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: -100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <div id="Products">
      <div className="inside-products-container">
        <motion.div variants={products}
          initial="hidden"
          whileInView="show" className="product-top-container">
          <motion.div variants={productsElementSlideRight} className="product-top-container-image-container">
            <img
              src={require("../../../assets/4x/flour_rice.png")}
              alt="natural-pahadi-flour-rice"
              className="product-top-container-image"                 width="100%" height="100%"
            />
          </motion.div>
          <div className="product-top-container-data" id="flours_rice">
            <motion.div variants={productsElementSlideLeft} className="product-top-container-data-title">
              FLOURS AND RICE
            </motion.div>
            <motion.div variants={productsElementSlideLeft} className="product-top-container-data-description">
              <p className="product-top-container-data-description-p">
                Naturally Pahadi’s single-grain, multi-grain flours and rice are
                minimally processed and sourced from small farms. We use a
                local-traditional variety of grains that are are indigenous to
                the Himalayan belt. The milling is done in water mills (gharats)
                via the cold press method which enhances the taste and preserves
                the nutrients.
              </p>
              <p className="product-top-container-data-description-p">
                Our flours are used to make healthy breads and Indian
                flatbreads. Our Red Rice is healthier option to any whole
                variety.{" "}
              </p>
              <p className="product-top-container-data-description-p">
                Our product range Five Grain Flour, Amaranth Flour (Rajgira),
                Whole Grains (Diabetic Flour), Masa Harina (Corn Flour),
                Buckwheat Flour(Kuttu), and Red Rice.
              </p>
            </motion.div>
            <motion.a variants={productsElementSlideLeft}
              href="../../../Naturally Pahadi B2B brochure.pdf"
              download="Naturally Pahadi B2B brochure.pdf"
              style={{ textDecoration: "none" }}
            >
              <div className="product-top-container-data-button">
                Download Brochure
              </div>
            </motion.a>
          </div>
        </motion.div>
        <motion.div variants={products}
          initial="hidden"
          whileInView="show" className="product-bottom-container" id="infusion_teas_mixes">
          <motion.div variants={productsElementSlideRight} className="product-bottom-left-container">
            <div className="product-bottom-image-container">
              <img
                src={require("../../../assets/4x/fussion_tea.png")}
                alt="fusion_tea"
                className="product-bottom-image"                  width="100%" height="100%"
              />
            </div>
            <div className="product-bottom-data">
              <div className="product-bottom-title">INFUSION TEAS</div>
              <div className="product-bottom-description">
                <div className="product-bottom-description-p">
                  The flora, leaves, fruits and berries that go into the making
                  of our blends are sourced straight from the Himalayas. They
                  are fresh and flavourful with high healing properties which
                  help to rejuvenate the body and nourish the soul. Our herbs
                  infused brews are like a superfood and an amazing way to
                  provide deep nourishment.
                </div>
                <div className="product-bottom-description-p">
                  Our product range HACH CH’I, BĀĀN, RÚMUK and XÓPAN.
                </div>
                <a
                  href="../../../Naturally Pahadi B2B brochure.pdf"
                  download="Naturally Pahadi B2B brochure.pdf"
                  style={{ textDecoration: "none" }}
                >
                  <div className="product-top-container-data-button">
                    Download Brochure
                  </div>
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div variants={productsElementSlideLeft} className="product-bottom-right-container">
            <div className="product-bottom-image-container">
              <img
                src={require("../../../assets/4x/premix.png")}
                alt="premixes"
                className="product-bottom-image"                  width="100%" height="100%"
              />
            </div>
            <div className="product-bottom-data">
              <div className="product-bottom-title">PREMIXES</div>
              <div className="product-bottom-description">
                <div className="product-bottom-description-p">
                  Made with love, most of our ingredients are directly sourced
                  from the Himalayan small scale farmers and are off the purest
                  quality.
                </div>
                <div className="product-bottom-description-p">
                  Our Premixes / Ready-to-cook are made so you can make healthy
                  food instantly. These products have minimal additives and
                  better packaging to increase the shelf life.
                </div>
                <div className="product-bottom-description-p">
                  Our product range Instant Barnyard Dosa, Instant Blueberry
                  Barnyard - Oats Pancake and Instant Choco Ragi - Buckwheat
                  Pancake.
                </div>
                <a
                  href="../../../Naturally Pahadi B2B brochure.pdf"
                  download="Naturally Pahadi B2B brochure.pdf"
                  style={{ textDecoration: "none" }}
                >
                  <div className="product-top-container-data-button">
                    Download Brochure
                  </div>
                </a>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Products;
