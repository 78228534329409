import React from "react";
import "./services.css";
import { motion, useInView, useIsPresent } from "framer-motion";

const Services = ({ openForm }) => {


  const service = {
    show: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };

  const serviceElementSlideRight = {
    hidden: {
      opacity: 0,
      x: -300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: 100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  const serviceElementSlideLeft = {
    hidden: {
      opacity: 0,
      x: 300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: -100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };


  return (
    <div id="Services">
      <div className="inside-service-container">
        <motion.div className="service-top-container" variants={service}
          initial="hidden"
          whileInView="show" id="bulk-orders">
          <motion.div variants={serviceElementSlideRight} className="service-top-image-container">
            <img
              src={require("../../../assets/4x/bulk_purchase.png")}
              alt="natural-pahadi-bulk-purchase"
              className="service-top-image"
              width="100%" height="100%"
            />
          </motion.div>
          <div className="service-top-data">
            <div className="inside-service-top-data">
              <motion.div variants={serviceElementSlideRight} className="service-top-title">
                NATURALLY PAHADI FOR BULK ORDERS
              </motion.div>
              <motion.div variants={serviceElementSlideRight} className="service-top-description">
                <p className="service-top-description-p">
                  Elevate your business and brand with unbeatable value for
                  money in bulk purchases of premium flours, red rice, premixes,
                  and teas. Tailored deliveries cater to your specific needs,
                  making us the ideal choice for manufacturers, caterers, and
                  bulk buyers seeking excellence without compromise.
                </p>
                <div
                  className="service-enquiry-button"
                  onClick={() => {
                    openForm(1);
                  }}
                >
                  Enquire Now
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
        <motion.div variants={service}
          initial="hidden"
          whileInView="show" className="service-bottom-container" id="gifting-service">
          <motion.div variants={serviceElementSlideRight} className="service-bottom-left-container">
            <div className="insider-service-bottom-left-container">
              <div className="service-bottom-left-image-container">
                <img
                  src={require("../../../assets/4x/book.png")}
                  alt="download_brochure"
                  className="service-bottom-left-image"
                  width="100%" height="100%"
                />
              </div>
              <div className="service-bottom-left-button-container">
                <a
                  href="../../../Naturally Pahadi B2B brochure.pdf"
                  download="Naturally Pahadi B2B brochure.pdf"
                >
                  <div className="service-bottom-left-button">
                    Download Brochure
                  </div>
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div variants={serviceElementSlideLeft} className="service-bottom-right-container">
            <div className="service-bottom-right-image-container">
              <img
                src={require("../../../assets/4x/gifts.png")}
                alt="naturally_pahadi_gifts"
                className="service-bottom-right-image"
                width="100%" height="100%"
              />
            </div>
            <div className="service-bottom-right-data">
              <motion.div variants={serviceElementSlideLeft} className="service-bottom-right-title">
                NATURALLY PAHADI FOR GIFTING SOLUTIONS
              </motion.div>
              <motion.div variants={serviceElementSlideLeft} className="service-bottom-description">
                <p className="service-bottom-description-p">
                  Transform your gifting experience. Explore our diverse range
                  of gifting solutions, featuring both branded gift boxes and
                  curated assortments. Our meticulously crafted gift boxes
                  showcase a blend of our premium flours, pre-mixes, and teas.
                  You can also opt for our products along with carefully
                  selected partner products in customised branded options. Ideal
                  for corporate gifting across various occasions, these curated
                  brand boxes deliver a distinctive and thoughtful touch to
                  every celebration.
                </p>
                <div
                  className="service-enquiry-button"
                  onClick={() => {
                    openForm(2);
                  }}
                >
                  Enquire Now
                </div>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
