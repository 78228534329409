import React, { memo, useEffect, useRef, useState } from "react";
import "./contactus.css";
import Alert from '@mui/material/Alert';
// import { sendMail, sendMailClient } from "../../../email";
import {motion} from 'framer-motion';

const ContactUs = () => {


  const [nameVal,setNameVal] = useState(null)
  const [designationVal,setDesignationVal] = useState(null)
  const [emailVal,setEmailVal] = useState(null)
  const [compVal,setCompVal] = useState(null)
  const [numberVal,setNumberVal] = useState(null)
  const [checked,setChecked] = useState(false)
  const [off,setOff] = useState(false)

  const name = useRef();
  const form = useRef();

  window.onload = function () {
    name.current.setAttribute("autoComplete", false);
  };

  const submitHandler = (event) => {
    const regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,3})(\.[a-z]{2,3})?$/;

    if(nameVal!=="" && designationVal!==""&& emailVal!=="" && compVal!=="" && numberVal!=="" && checked!==false && nameVal!==null  && designationVal!==null && emailVal!==null && compVal!==null && numberVal!==null){

      if(!emailVal.match(regex)){
        const emailElem = document.getElementById('email')
        emailElem.classList.remove('no-error')
      }
      else{
        //remove all errors except regex emails error
        console.log('sending')
          //can remove email error if any

        event.preventDefault();
        const body = `Hello,<br><br>A new message has arrived via the contact form.<br><br>The sender, <b>${nameVal}</b>, from <b>${compVal}</b>, holds the position of <b>${designationVal}</b> .<br><br>Their contact details:<br>Email: <b>${emailVal}</b><br>Number: <b>${numberVal}</b>.`
        const config = {
          Host: "smtp.elasticemail.com",
          Username: "naturallypahadi.b2b@gmail.com",
          Password: "8EB3DC96C156E75279401B68EBDC9D9FDCA1",
          To: "naturallypahadi.b2b@gmail.com",
          From: "naturallypahadi.b2b@gmail.com",
          Subject: "A New Enquiry from Naturally Pahadi's Contact Us form",
          Body: body,
        };

        setNameVal("")
        setDesignationVal("")
        setEmailVal("")
        setNumberVal("")
        setChecked(false)
        setCompVal("")
        if (window.Email) {
          window.Email.send(config).then(() =>   {setOff(!off)}
          );
        }
      }
    }
  };




  // adding animation
  const contactus = {
    show: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };

  const contactusElementSlideRight = {
    hidden: {
      opacity: 0,
      x: -300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: 100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  const contactusElementSlideLeft = {
    hidden: {
      opacity: 0,
      x: 300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: -100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };


  return (

    <div id="ContactUs">
        <Alert severity="success" className={`mode ${off?"":"off-mode"}`} onClose={() => {setOff(!off)}}>
          Successfully sent the message
        </Alert>
      <motion.div variants={contactus}
          initial="hidden"
          whileInView="show" className="inside-contactus-container">
        <motion.div variants={contactusElementSlideRight} className="contactus-left-container">
          <img
            src={require("../../../assets/4x/contaactus-cheif.png")}
            alt="contactus-img"
            className="contactus-left-img"
            width="100%" height="100%"
          />
        </motion.div>
        <motion.div variants={contactusElementSlideLeft} className="contactus-right-container">
          <img
            src={require("../../../assets/4x/blob.png")}
            alt="right-contactus-img"
            className="contactus-right-img"
            width="100%" height="100%"
          />
          <form action="" className="contactus-form" ref={form}>
            <div className="contactus-title contact-li">
              Please fill the below form for all call from our sales
              representatives:
            </div>
            <div className="contact-li">
              <label htmlFor="name">Name</label>
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  ref={name}
                  value={nameVal || ""}
                  id="name"
                  autoComplete="nope"
                  onChange={(e) => {
                    setNameVal(e.target.value);
                  }}
                  required
                />
                <div className="error no-error" >
                  Please enter your Name
                </div>
              </div>
            </div>
            <div className="contact-li">
              <label htmlFor="designation">Designation</label>
              <div className="input-container">
                <input
                  type="text"
                  name="designation"
                  id="designation"
                  value={designationVal || ""}
                  autoComplete="my-field-name1"
                  onChange={(e) => {
                    
                      setDesignationVal(e.target.value)
                    
                  }}
                  required
                />
                <div className="error no-error" >
                  Please enter your Designation
                </div>
              </div>
            </div>
            <div className="contact-li">
              <label htmlFor="comp_cafe">Company/Cafe</label>
              <div className="input-container">
                <input
                  type="text"
                  name="comp_cafe"
                  id="comp_cafe"
                  value={compVal || ""}
                  autoComplete="my-field-name2"
                  onChange={(e) => {
                    setCompVal(e.target.value);
                  }}
                  required
                />
                <div className="error no-error">
                  Please enter your Company/Cafe Name
                </div>
              </div>
            </div>
            <div className="contact-li">
              <label htmlFor="number">Mobile Number</label>
              <div className="input-container">
                <input
                  type="number"
                  name="number"
                  id="number"
                  value={numberVal || ""}
                  autoComplete="my-field-name3"
                  onChange={(e) => {
                    setNumberVal(e.target.value);
                  }}
                  required
                />
                <div className="error no-error">
                  Please enter your Mobile Number
                </div>
              </div>
            </div>
            <div className="contact-li">
              <label htmlFor="email">Email Id</label>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={emailVal || ""}
                  autoComplete="my-field-name4"
                  onChange={(e) => {
                    setEmailVal(e.target.value);
                  }}
                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  required
                />
                <div className="error no-error">Please enter your Email</div>
              </div>
            </div>
            <div className="contact-li checkbox-li">
              <input
                type="checkbox"
                name="checkbox"
                checked={checked}
                onChange={(e) => setChecked(!checked)}
                id="checkbox"
                required
              />
              <label htmlFor="checkbox" className="checkbox-label">
                I agree to receive related communications via phone and email.
                For more information on how we process your data for marketing
                communication. Check our Privacy policy.
              </label>
            </div>
            <button
              className="contactus-form-button"
              value="Send Email"
              onClick={submitHandler}
              type="submit"
            >
              Submit
            </button>
          </form>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ContactUs;
