import React, { useRef, useState } from "react";
import "./home.css";
import Banner from "../../components/Home/Banner/Banner";
import Links from "../../components/Home/Links/Links";
import AboutUs from "../../components/Home/AboutUs/AboutUs";
import Products from "../../components/Home/Products/Products";
import Services from "../../components/Home/Services/Services";
import ContactUs from "../../components/Home/ContactUs/ContactUs";
import { Alert } from "@mui/material";
import FAQs from "../../components/Home/FAQs/FAQs";

const Home = () => {
  const [formClose, setFormClose] = useState(false);
  const [floatingEmail, setFloatingEmail] = useState(null);
  const [floatingNumber, setFloatingNumber] = useState(null);
  const [off, setOff] = useState(false);
  const [body, setBody] = useState("");
  const [type, setType] = useState("Issue while sending email body");
  const floatEmailError = useRef()

  const openForm = (typeNum) => {
    setFormClose(!formClose);
    setType(typeNum);
  };

  const closeForm = () => {
    setFormClose(!formClose);
  };

  const handleEnquiry = (event) => {
    const regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,3})(\.[a-z]{2,3})?$/;
    if (
      floatingEmail !== null &&
      floatingEmail !== "" &&
      floatingNumber !== null &&
      floatingNumber !== ""
    ) {
      if (!floatingEmail.match(regex)) {
        // const emailElemError = document.getElementById("floating-email-error");
        floatEmailError.current.classList.remove("floating-email-no-error");
      } else {
        //remove all errors except regex emails error
        // floatEmailError.current.classList.remove("floating-email-no-error");
        //can remove email error if any

        event.preventDefault();
        let bod = ""
        if (type === 1) {
          bod = `Hello,<br><br>A new enquiry has arrived via the contact form. It is from, <br>Email: ${floatingEmail}<br>Number:${floatingNumber}<br> Regarding the Bulk Order Service`;
        } 
        if(type === 2) {
          bod = `Hello,<br><br>A new enquiry has arrived via the contact form. It is from, <br>Email: ${floatingEmail}<br>Number:${floatingNumber}<br> Regarding the Gifting Service`;
        }
        const config = {
          Host: "smtp.elasticemail.com",
          Username: "naturallypahadi.b2b@gmail.com",
          Password: "8EB3DC96C156E75279401B68EBDC9D9FDCA1",
          To: "naturallypahadi.b2b@gmail.com",
          From: "naturallypahadi.b2b@gmail.com",
          Subject: "A New Enquiry from Naturally Pahadi's Contact Us form",
          Body: bod,
        };


        if (window.Email) {
          window.Email.send(config).then(() => {
            setOff(!off);
            setFloatingEmail(null);
            setFloatingNumber(null);
            closeForm();
          });
        }
      }
    }
  };


  const handleBrochure =(url)=>{
    
  }
  return (
    <div className="Home">
      <Alert
        severity="success"
        className={`mode ${off ? "" : "off-mode"}`}
        onClose={() => {
          setOff(!off);
        }}
      >
        Successfully sent the message
      </Alert>
      <div
        className={`floating-form-container ${formClose ? "" : "form-close"}`}
      >
        <form action="" className="floating-form">
          <img
            src={require("../../assets/4x/close.png")}
            onClick={closeForm}
            alt="form-close-button"
            className="floating-form-close-button"
            width="100%" height="100%"
          />
          <div className="floating-form-title">
            Connecting with Care:New Enquiry!
          </div>
          <div className="floating-form-description">
            Kindly provide the following details so we can assist you with your
            enquiry.
          </div>
          <div className="floating-input-container">
            <input
              value={floatingEmail || ""}
              onChange={(e) => {
                setFloatingEmail(e.target.value);
              }}
              type="email"
              name="email"
              required
              ref={floatEmailError}
              pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
              id="floating-email"
              placeholder="Email Address*"
            />
            <div className="floating-email-error floating-email-no-error">
              Enter a Valid Email
            </div>
          </div>
          <div className="floating-input-container">
            <input
              value={floatingNumber || ""}
              onChange={(e) => {
                setFloatingNumber(e.target.value);
              }}
              type="number"
              name="number"
              required
              id="floating-number"
              placeholder="Phone Number*"
            />
          </div>
          <button type="submit" onClick={handleEnquiry}>
            Connect
          </button>
        </form>
      </div>
      <Banner />
      <Links openForm={openForm} />
      <AboutUs />
      <Products />
      <Services openForm={openForm}/>
      <FAQs/>
      <ContactUs />
    </div>
  );
};

export default Home;
