import React from "react";
import "./banner.css";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, useInView, useIsPresent } from "framer-motion";

const Banner = () => {
  // variants

  const banner = {
    show: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };

  const bannerElementSlideUp = {
    hidden: {
      opacity: 0,
      y: 300,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
    exit: {
      opacity: 0,
      y: -100,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  return (
    <div className="Banner">
      <div className="banner-container">
        <div className="inside-banner-container">
          <motion.div
            className="np-main-icon-container"
            variants={banner}
            initial="hidden"
            whileInView="show"
          >
            <motion.img
            variants={bannerElementSlideUp}
              className="np-main-icon-img"
              src={require("../../../assets/4x/np_icon.png")}
              alt="np_main_image"
              width="100%" height="100%"
            />
            <motion.div variants={bannerElementSlideUp} className="np-main-icon-title">FOR KITCHENS & CAFES</motion.div>
            <motion.div             variants={bannerElementSlideUp}    className="second-banner-image-container">
              <img
                src={require("../../../assets/4x/cheif_cafe.png")}
                alt="cheif-cafe-products"
                className="cheif-cafe-img"
                width="100%" height="100%"
              />
              <AnchorLink
                href="#flours_rice"
                className="floating-widget-1"
              >Flours</AnchorLink>
              <AnchorLink
                href="#infusion_teas_mixes"
                className="floating-widget-2"
              >Teas</AnchorLink>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
